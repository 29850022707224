import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeCartItem, toggleWidget } from 'services/cart';
import { useGetStrollerQuery, useGetStrollerAccessoriesQuery } from 'services/strollers';
import { getPriceHTML } from 'lib/numbers';

import { Icon, Skeleton } from 'UI';

const CartItem = ({ item }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const strollerProduct = item.products.find((product) => product.reference == 'STROLLER');

	const { data: stroller, error } = useGetStrollerQuery(strollerProduct.referenceId);
	const { data: accessories } = useGetStrollerAccessoriesQuery(strollerProduct.referenceId);

	useEffect(() => {
		if (error != null) {
			if (error.status == 404) {
				// Stroller no longer available, remove it from cart
				dispatch(removeCartItem(item));
			}
		}
	}, [error]);

	const getThumbnail = (images) => {
		if (images == null || images.length == 0) {
			return null;
		}

		return images[0].sizes?.find((image) => image.size == 'sm');
	};

	const strollerClick = (id) => {
		if (location.pathname == `/barnvagnar/${id}`) {
			// Only close widget if on current page
			dispatch(toggleWidget());
		}
	};

	if (stroller == null) {
		return (
			<div key={item.cartItemId} className="first:mt-0 mt-5 pb-5 border-b border-b-gray-300 last-of-type:pb-0 last-of-type:border-b-0">
				<div className="flex items-center">
					<div className="mr-4">
						<Skeleton className="w-20 h-20" />
					</div>

					<div className="flex-1">
						<Skeleton size="text-2xl" />

						<div>
							{getPriceHTML(strollerProduct)} / månad i {strollerProduct.subscriptionPeriod} månader
						</div>
					</div>
				</div>

				<div className="my-7 border-b border-gray-300 w-full"></div>

				{item.products != null && item.products.length > 0 ?
					<div className="mt-2">
						<div className="font-semibold mb-4">
							Dina tillval
						</div>

						{item.products
						.filter((product) => product.reference == 'ACCESSORY')
						.map((accessory) => (
							<div className="flex mb-2" key={accessory.productId}>
								<div className="flex-1">
									<Skeleton />
								</div>

								<div className="ml-2 whitespace-nowrap">
									{getPriceHTML(accessory)} / månad
								</div>
							</div>
						))}
					</div>
					: null}
			</div>
		);
	}

	const accessoriesProducts = item.products?.filter((product) => product.reference == 'ACCESSORY');

	return (
		<div key={item.cartItemId} className="first:mt-0 mt-5 pb-5 border-b border-b-gray-300 last-of-type:pb-0 last-of-type:border-b-0">
			<div className="flex items-center">
				{getThumbnail(stroller.images) != null ?
					<div className="mr-4">
						<img
							className="w-20 h-20 mix-blend-multiply border border-gray-300 rounded-lg p-3"
							src={getThumbnail(stroller.images).url}
							alt=""
						/>
					</div>
				: null}

				<div className="flex-1">
					<div className="flex items-center justify-between">
						<Link
							className="text-2xl font-semibold"
							onClick={() => strollerClick(stroller.strollerId)}
							to={`/barnvagnar/${stroller.strollerId}`}
						>
							{stroller.name}
						</Link>

						<span
							className="cursor-pointer h-6 transition-all ease-in-out hover:scale-110"
							onClick={() => dispatch(removeCartItem(item))}>
							<Icon name="Trash" className="!h-6" />
						</span>
					</div>

					<div>
						{getPriceHTML(strollerProduct)} / månad i {strollerProduct.subscriptionPeriod} månader
					</div>
				</div>
			</div>

			{accessoriesProducts.length > 0 ?
				<div>
					<div className="my-7 border-b border-gray-300 w-full"></div>

					<div className="font-semibold mb-4">
						Dina tillval
					</div>

					{accessoriesProducts.map((accessory) => {
						const data = accessories?.find((accessoryData) =>
							accessoryData.accessoryId == accessory.referenceId
						);

						if (data == null) {
							return (
								<div className="flex mb-2" key={accessory.productId}>
									<div className="flex-1">
										<Skeleton />
									</div>

									<div className="ml-2 whitespace-nowrap">
										{getPriceHTML(accessory)} / månad
									</div>
								</div>
							);
						}

						return (
							<div className="flex justify-between mb-2" key={accessory.productId}>
								<div>
									{data.name}
								</div>

								<div className="w-fit ml-2 whitespace-nowrap">
									{getPriceHTML(accessory)} / månad
								</div>
							</div>
						);
					})}
				</div>
				: null}
		</div>
	);
}

export default CartItem;
