import React from 'react';
import { Form, Field } from 'react-final-form';
import { getPriceHTML } from 'lib/numbers';

import AccessoriesSelect from 'components/Fields/AccessoriesSelect';
import StrollerSelect from 'components/Fields/StrollerSelect';

import { AnimatedDigit, Button, Icon } from 'UI';

const NewSubscriptionForm = (props) => {

	const onSubmit = (values, form) => {
		return new Promise((resolve) => {
			if (props.onSubmit != null && typeof props.onSubmit == 'function') {
				props.onSubmit(values)
					.then(() => {
						resolve(1);
						form.reset();
					});

				return true;
			}

			resolve(1);
		});
	};

	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit, submitting, form }) => {
				const products = form.getFieldState('products')?.value;
				let totals = 0;
				let strollerProduct = null;
				let accessories = null;

				if (products != null && products.length > 0) {
					strollerProduct = products.find((product) => product.reference === 'STROLLER');
					accessories = products.filter((product) => product.reference !== 'STROLLER');

					products.forEach((product) => {
						totals += product.price;
					});
				}

				return (
					<form onSubmit={handleSubmit}>
						<h2 className="text-2xl font-medium">Välj prenumerationsperiod</h2>

						<Field
							name="products"
							component={StrollerSelect}
							stroller={props.stroller}
						/>

						{props.accessories.length > 0 ?
							<h2 className="text-2xl mt-14 font-medium">Välj tillbehör</h2>
						: null}

						<div className="w-full mt-4">
							<Field
								name="products"
								component={AccessoriesSelect}
								accessories={props.accessories}
							/>
						</div>

						{products != null && products.length > 0 ?
							<>
								<h2 className="text-2xl mt-14 font-semibold">Översikt</h2>

								<div className="bg-koala p-8 mt-4 mb-4">
									<div className="border-b border-b-black mb-4 pb-6">
										{strollerProduct != null ?
											<div className="flex justify-between flex-col xl:flex-row">
												<div className="font-semibold">
													Prenumerationsperiod
												</div>

												<div>
													{getPriceHTML(strollerProduct)} / {strollerProduct.subscriptionPeriod} månader
												</div>
											</div>
											: null}

										{accessories != null ?
											accessories.map((accessory) => {
												return (
													<div className="flex justify-between mt-2 flex-col xl:flex-row" key={accessory.productId}>
														<div className="font-semibold">
															{accessory.name}
														</div>

														<div>
															{getPriceHTML(accessory)} / mån
														</div>
													</div>
												);
											})
											: null}
									</div>

									<div className="flex justify-between">
										<div>
											<span className="text-3xl font-bold">
												Totalt
											</span>
										</div>

										<div className="flex items-center">
											<span className="text-3xl font-bold">
												<AnimatedDigit>
													{totals}
												</AnimatedDigit>
											</span>

											<span className="ml-1">:- / mån</span>
										</div>
									</div>
								</div>
							</>
							: null}

						<div className="mt-10">
							<Button variant="green" className="w-full !py-6" disabled={submitting || strollerProduct == null}>
								Gå till kassan
								<Icon name="Arrow" className="ml-2" />
							</Button>
						</div>
					</form>
				);
			}}
		/>
	);

}

export default NewSubscriptionForm;