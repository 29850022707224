import { slugify } from 'lib/url';
import React from 'react';
import { Link } from 'react-router-dom';

const Brands = ({ brands }) => <>
	<div className="flex mb-8 items-center justify-between">
		<div className="text-3xl">
			Våra partners
		</div>
	</div>

	<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
		{brands != null ?
			brands.map((brand) => {
				const logo = brand.images.find((img) => img.type.toLowerCase() == 'logo');

				if (logo == null) {
					return null;
				}

				return (
					<Link
						to={`/varumarken/${slugify(brand.name)}-${brand.brandId}`}
						key={brand.brandId}
						className="bg-gray-100 flex items-center justify-center text-black relative"
					>
						<div className="p-8 md:p-10">
							<img
								className="mix-blend-multiply"
								src={logo.url}
								alt={brand.name + ' logotyp'}
							/>
						</div>
					</Link>
				);
			})
		: null}
	</div>
</>

Brands.position = 'brands';
export default Brands;