import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Swiper } from 'UI';

/**
 * @typedef {Object} Image
 * @property {string} url
 */

const Gallery = (props) => {
	const [swiper, setSwiper] = useState(null);
	const [activeSlide, setActiveSlide] = useState(0);

	const slideTo = (index) => swiper?.slideTo(index);

	/**
	 * @param {Image} image
	 * @param {number} index
	 */
	const renderImage = (image, index) => (
		<div
			key={`image-${index}`}
			className={cx(
				'h-full',
				'w-full',
				'relative',
				'flex',
				'items-center',
				'justify-center',
				'bg-gray-100',
			)}
		>
			<img
				className={cx(
					'w-auto',
					'h-full',
					'mix-blend-multiply',
					'absolute',
					'object-contain',
				)}
				src={image.url}
				alt=""
			/>
		</div>
	);

	/**
	 * @param {Image} image
	 * @param {number} index
	 */
	const renderThumbnail = (image, index) => (
		<div
			className={cx(
				'w-16',
				'h-16',
				'flex',
				'items-center',
				'justify-center',
				'bg-gray-100',
				'border',
				'relative',
				'cursor-pointer',
				'transition-all',
				{
					'border-transparent': index != activeSlide,
					'border-black': index == activeSlide,
				}
			)}
			onClick={() => slideTo(index)}
			key={`thumb-${index}`}
		>
			<img
				className={cx(
					'h-full',
					'absolute',
					'top-0',
					'mix-blend-multiply',
					'bg-no-repeat',
					'bg-contain',
					'bg-center',
				)}
				src={image.url}
				alt=""
			/>
		</div>
	);

	if (props.images == null) {
		return null;
	}

	return (
		<Fragment>
			<Swiper
				onSlideChange={(slide) => setActiveSlide(slide.realIndex)}
				onSwiper={setSwiper}
			>
				{props.images.map(renderImage)}
			</Swiper>

			<div className="flex gap-3 mt-3 flex-wrap absolute bottom-0 left-0 p-8">
				{props.images.map(renderThumbnail)}
			</div>
		</Fragment>
	);
};

Gallery.defaultProps = {
	images: [],
};

Gallery.propTypes = {
	images: PropTypes.arrayOf(PropTypes.shape({
		url: PropTypes.string.isRequired,
	})),
};

export default Gallery;