import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiUrl } from 'lib/env';

export const brandsApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: getApiUrl() + 'api/v1/'
	}),
	reducerPath: 'brands',
	tagTypes: ['Brand', 'Brands'],
	endpoints: (build) => ({
		getBrands: build.query({
			query: () => ({
				url: `brands`
			}),
			providesTags: ['Brands'],
		}),
		getBrand: build.query({
			query: (id) => ({
				url: `brands/${id}`
			}),
			providesTags: (result, error, id) => [{ type: 'Brand', id: parseInt(id) }],
		}),
	}),
});

export const {
	useGetBrandsQuery,
	useGetBrandQuery,
} = brandsApi;