import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { formatPrice } from 'lib/numbers';
import {
	useCreateStrollySubscriptionMutation,
	useCreateSubscriptionMutation,
	useGetPaymentInformationQuery,
	useValidateCampaignCodeMutation,
} from 'services/subscriptions';
import {
	useGetCartDeliveries,
	useGetCartItems,
	useGetCartPaymentMethod,
	useGetCartSubscriptionPeriod,
	useGetCartTotals,
} from 'hooks/cart';
import { usePage } from 'hooks/page';
import { useOnRouteChange } from 'hooks/routes';

import Header from 'components/Header';
import Footer from 'components/Footer';

import CampaignCodeForm from 'containers/Forms/CampaignCode';
import CustomerCheckoutForm from 'containers/Forms/CustomerCheckout';
import CartItem from 'components/Cart/CartItem';
import TermsForm from 'containers/Forms/TermsForm';
import BankIdSignForm from 'containers/Forms/BankIdSign';

import { Button, Icon, Modal, Select } from 'UI';
import { appInsights } from 'lib/trackers';

const Container = ({ children }) => {
	useOnRouteChange();

	return (
		<div className="flex flex-col justify-between min-h-full">
			<div className="bg-gray-100 -mb-28 min-h-full flex-1">
				<Header />

				<div className="c--container py-12 md:py-24 min-h-full">
					{children}
				</div>
			</div>

			<Footer />
		</div>
	);

};

const Checkout = () => {
	const [registerPosition, SEO] = usePage('checkout');
	const [bankIdReference, setBankIdReference] = useState(null);
	const items = useGetCartItems();
	const deliveryOptions = useGetCartDeliveries();
	const cartTotals = useGetCartTotals();
	const subscriptionPeriod = useGetCartSubscriptionPeriod();
	const appliedCoupon = useSelector((state) => state.cart.appliedCoupon);
	const { data: paymentInformation } = useGetPaymentInformationQuery(subscriptionPeriod);
	const [createSubscription, subscription] = useCreateSubscriptionMutation();
	const [createStrollySubscription, strollySubscription] = useCreateStrollySubscriptionMutation();
	const [validateCampaign] = useValidateCampaignCodeMutation();
	const [customer, setCustomer] = useState(null);
	const [deliveryId, setDeliveryId] = useState(null);
	const [checkoutError, setCheckoutError] = useState(false);
	const [termsValid, setTermsValid] = useState(false);
	const paymentMethod = useGetCartPaymentMethod();

	useEffect(() => {
		if (appliedCoupon != null && appliedCoupon != '') {
			onCampaignCodeSubmit({
				code: appliedCoupon,
			});
		}
	}, []);

	useEffect(() => {
		const { status, data } = subscription;
		if (status == 'fulfilled') {
			if (data.bookPaymentStatus == 'DENIED') {
				appInsights.trackEvent({
					name: 'CheckoutBookPaymentStatusDenied',
					properties: {
						data: data,
					}
				});

				setCheckoutError(true);
			}

			if (data.bookPaymentStatus == 'SIGNING' && data.signingUrl != null) {
				window.location.href = data.signingUrl;
			}
		}
	}, [subscription]);

	const valid = useMemo(() => {
		if (customer == null || deliveryId == null || deliveryId == '0') {
			return false;
		}

		if (!termsValid) {
			return false;
		}

		return true;
	}, [customer, deliveryId, termsValid]);

	const onCustomerSubmit = (values) => new Promise((resolve) => {
		setCustomer(values);
		resolve(1);
	});

	const onCampaignCodeSubmit = (values) => new Promise((resolve) => {
		let stroller = null;
		items.forEach((cartItem) => {
			let foundStroller = cartItem.products.find((product) => product.reference == 'STROLLER');
			if (foundStroller != null) {
				stroller = foundStroller;
			}
		});

		if (stroller != null) {
			validateCampaign({
				...values,
				productId: stroller.referenceId,
			}).then(() => {
				appInsights.trackEvent({
					name: 'CheckoutAppliedCoupon',
					properties: {
						code: values.code,
						productId: values.productId,
					}
				});

				resolve(1);
			});
		} else {
			resolve(1);
		}
	});

	const onTermsAccept = (values) => {
		appInsights.trackEvent({
			name: 'CheckoutAcceptedTerms',
		});

		setTermsValid(values);
	};

	const onCheckoutSubmit = async () => {
		let products = [];

		items.forEach((cartItem) => {
			products = [
				...products,
				...cartItem.products.map((product) => ({
					reference: product.reference,
					productId: product.productId,
				}))
			];
		});

		let values = {
			...customer,
			subscriptionId: 0,
			deliveryId: parseInt(deliveryId),
			products,
			additionalRequestsInfo: '',
			campaignCode: '',
			redirectUrl: window.location.origin + '/tack',
		};

		appInsights.trackEvent({
			name: 'CheckoutSubmit',
			properties: {
				appliedCoupon: appliedCoupon,
				deliveryId: parseInt(deliveryId),
				products: products,
				redirectUrl: window.location.origin + '/tack',
			},
		});

		if (paymentMethod == 'STROLLY') {
			const response = await createStrollySubscription(values).unwrap();
			setBankIdReference(response.orderRef);
		} else {
			createSubscription(values);
		}
	};

	if (items.length == 0) {
		return (
			<Container>
				<div className="flex items-center justify-center flex-col">
					<h1 className="text-4xl font-semibold">
						Din varukorg är tom!
					</h1>

					<div className="mt-4">
						<Link to="/barnvagnar">
							<Button>
								Hitta en vagn i vår barnvagnspark
								<Icon name="Arrow" className="ml-2" />
							</Button>
						</Link>
					</div>
				</div>
			</Container>
		);
	}

	return (
		<Container>
			<SEO>
				<title>Kassan</title>
			</SEO>

			<Modal
				open={bankIdReference != null}
				type="small"
				onClose={() => setBankIdReference(null)}
				title={(
					<>
						<Icon name="Bankid" className="!h-12" /> Signera med BankId
					</>
				)}
			>
				<BankIdSignForm
					reference={bankIdReference}
					onComplete={(response) => {
						setBankIdReference(null);

						if (response.status == 'complete') {
							window.location.href = window.location.origin + '/tack?order=' + response.orderRef;
						}
					}}
					onError={(error) => {
						setBankIdReference(null);
						setCheckoutError(true);
					}}
				/>
			</Modal>

			<Modal title="Något gick fel" type='small' open={checkoutError} onClose={() => setCheckoutError(false)}>
				<div className="p-10">
					<h3 className="text-2xl font-semibold">
						Din order gick tyvärr inte igenom!
					</h3>

					<p className="mt-4">
						För mer information kontakta oss på <a href="mailto:info@strollysweden.com" className="text-blue">info@strollysweden.com</a>
					</p>
				</div>
			</Modal>

			<div className="mb-6">
				<h1 className="text-4xl font-semibold">Din varukorg</h1>
			</div>

			<div className="flex flex-wrap">

				<div className="w-full lg:w-7/12 lg:pr-5">
					<div className="bg-white rounded-md p-10">
						<div>
							<h2 className="text-2xl font-semibold mb-4">Kampanjkod</h2>

							<CampaignCodeForm
								onSubmit={onCampaignCodeSubmit}
							/>
						</div>

						<div className="my-7 border-b border-gray-300 w-full"></div>

						<div>
							<h2 className="text-2xl font-semibold mb-4">Dina uppgifter</h2>

							<CustomerCheckoutForm
								onSubmit={onCustomerSubmit}
							/>
						</div>
					</div>

					<div className="mt-10 bg-white rounded-md p-10">
						<h2 className="text-2xl font-semibold mb-4">Leverans</h2>

						<Select onChange={(e) => setDeliveryId(e.target.value)}>
							<option value="0">Välj leveransalternativ</option>

							{deliveryOptions
							.filter((delivery) => delivery.name.indexOf('Månader') == -1)
							.map((delivery) =>
								<option value={delivery.deliveryId} key={delivery.deliveryId}>
									{delivery.name}
								</option>
							)}
						</Select>
					</div>
				</div>

				<div className="w-full lg:w-5/12 lg:pl-5 mt-10 lg:mt-0">
					<div className="bg-white rounded-md p-10">
						{items.map((item) =>
							<CartItem key={item.cartItemId} item={item} />
						)}

						<div className="flex justify-between items-center mt-7">
							<div className="text-lg font-semibold">
								Startavgift
							</div>

							<div className="text-lg font-semibold">
								{formatPrice(245)}
							</div>
						</div>

						<div className="flex justify-between items-center mt-2">
							<div>
								<div className="text-lg font-semibold">
									Total månadsavgift
								</div>

								<div className="text-sm text-gray-700/70">
									Inklusive moms
								</div>
							</div>

							<div className="text-2xl font-semibold">
								{formatPrice(cartTotals)}
							</div>
						</div>

						<div className="my-7 border-b border-gray-300 w-full"></div>

						{paymentMethod == 'RESURS' ?
							<div>
								<h3 className="text-lg font-semibold">
									Villkor för betalning
								</h3>

								{paymentInformation != null ?
									paymentInformation.map((link) => (
										<a target="_blank" rel="noreferrer" className="text-blue font-semibold" href={link.url + (cartTotals * subscriptionPeriod)}>
											{link.endUserDescription}
										</a>
									))
									: null}
							</div>
						: null}

						<div className="mt-4">
							<TermsForm onSubmit={onTermsAccept} />
						</div>

						<Button
							className="w-full mt-7 !py-6"
							onClick={onCheckoutSubmit}
							disabled={!valid || subscription.status == 'pending' || strollySubscription.status == 'pending'}
						>
							Gå till betalning
						</Button>

						{paymentMethod == 'RESURS' ?
							<div className="mt-2 text-sm text-gray-700 text-center">
								Betalning hanteras av Resurs bank och när du slutför din order och godkänner villkoren för betalningen godkänner du samtidigt våra villkor.<br />
								<img src="https://image.strollysweden.com/images/website/resurs-bank-logo-66x23.png" alt="ResursBank logo" className="inline-block mt-4" />
							</div>
						: null}
					</div>
				</div>

			</div>
		</Container>
	);

};

export default Checkout;