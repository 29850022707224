import React from 'react';

const BrandHero = ({ brand }) => {

	if (brand == null) {
		return null;
	}

	const image = brand.images.find((img) => img.type.toLowerCase() == 'gallery');

	return (
		<div className="mb-14 bg-moonlight flex flex-wrap">
			<div className="p-16 w-full md:w-7/12 order-last md:order-first">
				<h2 className="text-3xl font-semibold">
					{brand.name}
				</h2>

				<p className="mt-2">
					{brand.description}
				</p>
			</div>

			{image != null ?
				<div
					className="w-full md:w-5/12 bg-center bg-cover pt-[65%] md:pt-0"
					style={{ backgroundImage: "url('"+ image.url +"')" }}
				/>
			: null}
		</div>
	);

};

BrandHero.position = 'before_list';

export default BrandHero;