import { slugify } from 'lib/url';
import { formatPrice } from 'lib/numbers';
import { Link } from 'react-router-dom';
import { Icon } from 'UI';

const StrollerCard = ({ stroller }) => {

	const starCount = 3;
	let image = null;
	let price = null;

	if (stroller.images != null && stroller.images.length > 0) {
		if (stroller.images[0].sizes != null) {
			let small = stroller.images[0].sizes.find(size => size.size == 'sm');
			if (small != null) {
				image = small;
			}
		}
	};

	if (stroller.products != null) {
		let lowest = stroller.products.reduce((lowest, product) => {
			if (lowest == null || product.price < lowest.price) {
				return product;
			}

			return lowest;
		});

		price = lowest;
	};

	const getStars = (stars) => {
		return [...new Array(starCount)].map((arr, index) => {
			return index < stars ?
				<Icon name="Star" key={index} />
			: <Icon name="Star_Outline" key={index} />
		});
	};

	return (
		<Link className="flex-1" to={`/barnvagnar/${slugify(stroller.name)}-${stroller.strollerId}`}>
			<div className="bg-gray-100 p-6 mb-4">
				<div className="flex items-center">
					{getStars(stroller.stars)}
				</div>

				{image != null ?
					<div className="pt-12 pb-6">
						<div
							className="w-full mix-blend-multiply h-64 bg-no-repeat bg-contain bg-center"
							style={{ backgroundImage: 'url(' + image.url + ')' }}
						/>
					</div>
					:
					<div className="pt-12 pb-6">
						<div className="w-full h-64" />
					</div>
					}
			</div>

			<div className="text-gray-900 font-semibold mb-2">
				{stroller.name}
			</div>

			{price != null ?
				<div className="text-gray-900">
					Från <span className="font-semibold">{formatPrice(price.price)} / mån</span>
				</div>
				: null}
		</Link>
	);

};

export default StrollerCard;